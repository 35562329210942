import React from 'react';


function EditAvistamento(props){
    //componente de edição de observações do tipo avistamento. Todo o processo é handled pelo parent component, os dados a mostrar e funções a chamar chegam como props.
    //este componente apenas lida com os dados específicos deste tipo de observação, os dados comuns a todos os tipos são editados no parent
    return(
        <>
            <select id='especie' name='especie' onChange={props.handleChange} value={props.observation.properties.especie} target={'especie'}>
                <option value='Não sei' selected={props.observation.properties.especie==='Não sei'}>Não sei</option>
                <option value='Larus michahellis' selected={props.observation.properties.especie==='Larus michahellis'}>Larus michahellis</option>
                <option value='Larus fuscus' selected={props.observation.properties.especie==='Larus fuscus'}>Larus fuscus</option>
            </select>
            
            
            <div className='form-element'>
                    <label htmlFor='numero' style={{display:'block'}}>Número de indivíduos</label>
                    <input type='number' name='numero' id='numero' min={1} value={parseInt(props.observation.properties.numero)} onChange={props.handleChange} style={{width:'100%', boxSizing: 'border-box'}}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='vida' style={{display:'block'}}>Espécime(s) vivo(s)</label>
                    <input type='checkbox' name='vida' id='vida' onChange={props.handleChange} checked={props.observation.properties.vida}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='ruido' style={{display:'block'}}>A causar ruído</label>
                    <input type='checkbox' name='ruido' id='ruido' onChange={props.handleChange} checked={props.observation.properties.ruido}/>
                </div>
                <div className='form-element' style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
                    <label htmlFor='lixo' style={{display:'block'}}>A mexer em lixo urbano</label>
                    <input type='checkbox' name='lixo' id='lixo' onChange={props.handleChange} checked={props.observation.properties.lixo}/>
                </div>
                <div className='form-element'>
                    <label htmlFor='alimentacao' style={{display:'block'}}>Alimentação</label>
                    <select id='alimentacao' name='alimentacao' onChange={props.handleChange} style={{width:'100%'}}>
                        <option value='Não se está a alimentar' selected={props.observation.properties.alimentacao==='Não se está a alimentar'}>Não se está a alimentar</option>
                        <option value='A alimentar-se' selected={props.observation.properties.alimentacao==='A alimentar-se'}>A alimentar-se</option>
                        <option value='A ser alimentada' selected={props.observation.properties.alimentacao==='A ser alimentada'}>A ser alimentada</option>
                    </select>
                </div>
                <div className='form-element'>
                    <label htmlFor='detalhes' style={{display:'block'}}>Detalhes</label>
                    <textarea type='text' name='detalhes' id='detalhes' rows={'5'} cols={'40'} value={props.observation.properties.detalhes} onChange={props.handleChange} style={{width:'100%', boxSizing:'border-box'}}></textarea>
                </div>
        </>
    )
}

export default EditAvistamento;
